import { environmentConfig } from "@src/appV2/environment";
import { BookabilityUnmetCriteria } from "@src/appV2/OpenShifts/ShiftAction/types";

export const SHIFT_INVITES_API_PATH = "/shift-invites";
export const SHIFT_INVITES_API_URL = `${environmentConfig.REACT_APP_BASE_API_URL}${SHIFT_INVITES_API_PATH}`;

const SHIFT_INVITE_ERROR_FAILED_TO_ACCEPT_MESSAGE =
  "Failed to accept shift invite. Please try again later.";
const SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE = "This invite is no longer available.";

export const ShiftInviteErrors: Record<BookabilityUnmetCriteria, string> = {
  [BookabilityUnmetCriteria.FACILITY_CHARGE_RATE_MISSING]: SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.FACILITY_PENDING]: SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.FACILITY_SUSPENDED]: SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.SHIFT_ALREADY_GRABBED]: SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.SHIFT_DELETED]: SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.SHIFT_IN_PAST]:
    "This invite is for a past shift, and cannot be accepted.",
  [BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_DISABLED]: SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_LEAD_TIME_NOT_SET]:
    SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.SHIFT_INVALID]: SHIFT_INVITE_ERROR_FAILED_TO_ACCEPT_MESSAGE,
  [BookabilityUnmetCriteria.SHIFT_OVERLAPPING]:
    "You have an overlapping shift, and cannot accept this invite.",
  [BookabilityUnmetCriteria.SHIFT_OVERWORKING]: SHIFT_INVITE_ERROR_FAILED_TO_ACCEPT_MESSAGE,
  [BookabilityUnmetCriteria.WORKER_ACCOUNT_IN_REVIEW]: SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.WORKER_CANT_CLAIM_SHIFT]: SHIFT_INVITE_ERROR_FAILED_TO_ACCEPT_MESSAGE,
  [BookabilityUnmetCriteria.WORKER_DEACTIVATED]: SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.WORKER_DISABLED_TEST_ACCOUNT]: "Not Allowed: Test Account.",
  [BookabilityUnmetCriteria.WORKER_MISSING_LICENSE]:
    "You are missing a required license and cannot accept this shift invite.",
  [BookabilityUnmetCriteria.WORKER_MISSING_REQUIREMENTS]:
    "You are missing requirements and cannot accept this shift invite.",
  [BookabilityUnmetCriteria.WORKER_PAYMENTS_DISABLED]:
    "You must enroll in Stripe to accept this shift invite.",
  [BookabilityUnmetCriteria.WORKER_PENDING_DOCUMENTS]:
    "You have a pending required document and cannot accept this shift invite.",
  [BookabilityUnmetCriteria.WORKER_RESTRICTED]: SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.WORKER_UNSIGNED_AGREEMENT]:
    "To book this shift invite you must sign the latest independent contractor agreement.",
  [BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES]:
    SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_ASSESSMENTS]:
    SHIFT_INVITE_ERROR_NOT_AVAILABLE_MESSAGE,
  [BookabilityUnmetCriteria.WORKER_HAS_REACHED_DNR_LIMIT]:
    "You have reached the maximum DNR limit.",
  [BookabilityUnmetCriteria.WORKER_HAS_REACHED_MAX_HOURS_PER_WEEK]:
    "You have reached the maximum number of worked hours per week.",
  [BookabilityUnmetCriteria.WORKER_HAS_REACHED_MAX_CONSECUTIVE_HOURS]:
    "You have reached the maximum number of consecutive hours worked.",
  [BookabilityUnmetCriteria.WORKER_MULTI_FACILITY_WEEKLY_HOUR_MAXIMUM]:
    "You have reached the maximum number of worked hours per week across multiple facilities.",
};
